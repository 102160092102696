// people
export const FLAVIA_IMG =
  'https://d17hukvrbysd4e.cloudfront.net/staticSiteDesignImages/flavia.jpeg'

export const GUILLERMO_IMG =
  'https://d17hukvrbysd4e.cloudfront.net/staticSiteDesignImages/guillermo.jpg'

export const JACK_IMG =
  'https://d17hukvrbysd4e.cloudfront.net/staticSiteDesignImages/jack.jpg'

export const LUCY_IMG =
  'https://d17hukvrbysd4e.cloudfront.net/staticSiteDesignImages/lucy.jpg'

export const RUBEN_IMG =
  'https://d17hukvrbysd4e.cloudfront.net/staticSiteDesignImages/ruben.jpeg'

export const RICHARD_IMG =
  'https://d17hukvrbysd4e.cloudfront.net/staticSiteDesignImages/richardphoto.jpg'

export const ROB_IMG =
  'https://d17hukvrbysd4e.cloudfront.net/staticSiteDesignImages/rob.jpeg'

export const STEPHEN_IMG =
  'https://d17hukvrbysd4e.cloudfront.net/staticSiteDesignImages/stephen.jpg'

// seneca logo
export const SENECA_LOGO =
  'https://d17hukvrbysd4e.cloudfront.net/staticSiteDesignImages/senecaBlueLogo.svg'

export const SENECA_BY_GO_STUDENT_LOGO =
  'https://d17hukvrbysd4e.cloudfront.net/staticSiteDesignImages/senecaByGoStudentBlueLogo.svg'

// flags

export const FLAG_BR = 'https://d17hukvrbysd4e.cloudfront.net/assets/flagBR.svg'

export const FLAG_MX = 'https://d17hukvrbysd4e.cloudfront.net/assets/flagMX.svg'

export const FLAG_UK = 'https://d17hukvrbysd4e.cloudfront.net/assets/flagUK.svg'

export const FLAG_US = 'https://d17hukvrbysd4e.cloudfront.net/assets/flagUS.svg'

export const FLAG_AT = 'https://d17hukvrbysd4e.cloudfront.net/assets/flagAT.svg'

// resources image
export const RESOURCES =
  'https://d17hukvrbysd4e.cloudfront.net/staticSiteDesignImages/resources-min.png'
