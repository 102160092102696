import { useRef, useEffect } from 'react';

const useIconRotation = () => {
  const iconRef = useRef(null);
  const previousAngle = useRef(0);
  const totalRotationRef = useRef(0);

  // rotation constants
  const PROXIMITY_THRESHOLD = 150; // pixels
  const ROTATION_DAMPENING = 0.5; // rotation intensity
  const TRANSITION_SPEED = 0.2; // seconds

  const handleMouseMove = e => {
    if (!iconRef.current) return;

    const icon = iconRef.current;
    const rect = icon.getBoundingClientRect();
    const iconCenterX = rect.left + rect.width / 2;
    const iconCenterY = rect.top + rect.height / 2;

    const deltaX = e.clientX - iconCenterX;
    const deltaY = e.clientY - iconCenterY;
    const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

    // this is to avoid icons moving no matter how far away the cursor is
    if (distance > PROXIMITY_THRESHOLD) {
      return;
    }

    let currentAngle = Math.atan2(deltaY, deltaX) * (180 / Math.PI);

    // to track full rotations
    let angleDiff = currentAngle - previousAngle.current;

    if (angleDiff > 180) {
      angleDiff -= 360;
    } else if (angleDiff < -180) {
      angleDiff += 360;
    }

    totalRotationRef.current += angleDiff * ROTATION_DAMPENING;
    previousAngle.current = currentAngle;

    // for smooth transition
    icon.style.transition = `transform ${TRANSITION_SPEED}s ease-out`;
    icon.style.transform = `rotate(${totalRotationRef.current}deg)`;
  };

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  return iconRef;
};

export default useIconRotation;