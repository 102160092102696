// @flow
import React, { useRef, useState } from 'react'
import { FaPlay } from 'react-icons/fa'

import styles from './VideoPreview.module.scss'

type Props = {|
  videoLink: string,
  hasControls?: boolean,
  autoPlay?: boolean,
|}

export default function VideoPreview({
  videoLink,
  hasControls,
  autoPlay,
}: Props) {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [playing, setPlaying] = useState(autoPlay ?? false)

  const handlePlayVideo = () => {
    videoRef.current.play()
    videoRef.current.setAttribute('muted', false)
    setPlaying(true)
  }

  const handlePauseVideo = () => {
    videoRef.current.pause()
    setPlaying(false)
  }

  return (
    <div className={styles.video}>
      <video
        preload="auto"
        ref={videoRef}
        loop
        onClick={handlePauseVideo}
        playsInline
        controls={hasControls}
        autoPlay={autoPlay}
        muted={autoPlay}
      >
        <source src={videoLink} type="video/mp4" />
      </video>
      {playing || (
        <div className={styles.overlay}>
          <button className={styles.button} onClick={handlePlayVideo}>
            <FaPlay />
          </button>
        </div>
      )}
    </div>
  )
}
