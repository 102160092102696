import magnet from '../../../../common/images/icons/Magnet.svg'
import mathOperations from '../../../../common/images/icons/MathOperations.svg'
import filmReel from '../../../../common/images/icons/FilmReel.svg'
import scroll from '../../../../common/images/icons/Scroll.svg'
import strategy from '../../../../common/images/icons/Strategy.svg'
import globeHemisphereWest from '../../../../common/images/icons/GlobeHemisphereWest.svg'
import translate from '../../../../common/images/icons/Translate.svg'
import forkKnife from '../../../../common/images/icons/ForkKnife.svg'
import flask from '../../../../common/images/icons/Flask.svg'
import gearFine from '../../../../common/images/icons/GearFine.svg'
import dna from '../../../../common/images/icons/Dna.svg'
import personsArmsSpread from '../../../../common/images/icons/PersonsArmsSpread.svg'
import cpu from '../../../../common/images/icons/Cpu.svg'
import users from '../../../../common/images/icons/Users.svg'
import { IconData } from '../types/IconData'

const icons: IconData[] = [
  {
    name: 'magnet',
    src: magnet,
  },
  {
    name: 'mathOperations',
    src: mathOperations,
  },
  {
    name: 'filmReel',
    src: filmReel,
  },
  {
    name: 'scroll',
    src: scroll,
  },
  {
    name: 'strategy',
    src: strategy,
  },
  {
    name: 'globeHemisphereWest',
    src: globeHemisphereWest,
  },
  {
    name: 'translate',
    src: translate,
  },
  {
    name: 'forkKnife',
    src: forkKnife,
  },
  {
    name: 'flask',
    src: flask,
  },
  {
    name: 'gearFine',
    src: gearFine,
  },
  {
    name: 'dna',
    src: dna,
  },
  {
    name: 'personsArmsSpread',
    src: personsArmsSpread,
  },
  {
    name: 'cpu',
    src: cpu,
  },
  {
    name: 'users',
    src: users,
  },
];

export default icons;