// @flow
import React from 'react'
import classNames from 'classnames'

import PageRow from 'src/common/components/PageRow'
import PostContent from 'src/common/components/PostContent'
import { ButtonLink } from 'src/common/components/Button'
import Image from '../../../common/components/Image'
import VideoPreview from '../../../common/components/VideoPreview'
import { SenecaLink, SenecaLinkExternal } from '../../SenecaLink/SenecaLink'
import { Dot } from '../../Dot/Dot'
import { IconBackground } from './Icon/Icon'

import styles from './LandingPageHeader.module.scss'
import { LandingPageHeaderProps } from './types/LandingPageHeaderProps'
import icons from './consts/icons'

export default function LandingPageHeader({
  heading,
  description,
  className,
  primaryButton,
  secondaryButtons,
  asset,
  assetAlt,
}: LandingPageHeaderProps) {
  return (
    <PageRow firstRow className={classNames(styles.pageRow, className)}>
      <div className={styles.container}>
        <h1
          className={styles.header}
          dangerouslySetInnerHTML={{ __html: heading }}
        />
        <div className={styles.content}>
          <PostContent content={description} />
        </div>
        <div className={styles.buttonContainer}>
          {primaryButton && <ButtonLink color="blue" {...primaryButton} />}
          {secondaryButtons && (
            <div className={styles.secondaryButtons}>
              {secondaryButtons[0].external ? (
                <SenecaLinkExternal
                  link={secondaryButtons[0].linkExternal}
                  openInNewTab={secondaryButtons[0].openInNewTab}
                >
                  {secondaryButtons[0].text}
                </SenecaLinkExternal>
              ) : (
                <SenecaLink
                  key={secondaryButtons[0].text}
                  to={secondaryButtons[0].linkInternal}
                  openInNewTab={secondaryButtons[0].openInNewTab}
                >
                  {secondaryButtons[0].text}
                </SenecaLink>
              )}

              {secondaryButtons[1] && (
                <>
                  <Dot />
                  {secondaryButtons[1].external ? (
                    <SenecaLinkExternal
                      link={secondaryButtons[1].linkExternal}
                      openInNewTab={secondaryButtons[1].openInNewTab}
                    >
                      {secondaryButtons[1].text}
                    </SenecaLinkExternal>
                  ) : (
                    <SenecaLink
                      key={secondaryButtons[1].text}
                      to={secondaryButtons[1].linkInternal}
                      openInNewTab={secondaryButtons[1].openInNewTab}
                    >
                      {secondaryButtons[1].text}
                    </SenecaLink>
                  )}
                </>
              )}
            </div>
          )}
        </div>
        <div className={styles.imageContainer}>
          <IconBackground icons={icons} />
          {asset.endsWith('.mp4') ? (
            <VideoPreview videoLink={asset} autoPlay />
          ) : (
            <Image
              src={asset}
              alt={assetAlt}
              widths={{ mobile: 430, tablet: 650, desktop: 826 }}
            />
          )}
        </div>
      </div>
    </PageRow>
  )
}
