// @flow
import { motion } from 'framer-motion'
import React from 'react'
import { isMobile } from 'react-device-detect'

import { Icon } from '../../common/components/Icon/Icon'
import styles from './SubjectCarousel.module.scss'
import iconStyles from './SubjectIcon.module.scss'
import { Dot } from '../Dot/Dot'
import { SenecaLinkExternal } from '../SenecaLink/SenecaLink'
import { chunkArray } from '../../common/utils'
import { parseUrl, stringifyUrl } from 'query-string'

type SubjectLink = {|
  baseUrl: string,
  subject: string,
  ageGroup: string,
|}

type Subjects = {|
  heading: string,
  link: {|
    text: string,
    to: string,
  |},
  ageGroups: {|
    list: {|
      name: string,
    |}[],
  |},
  list: {|
    name: string,
    category: string,
  |}[],
  subjectLink: SubjectLink,
|}

type Props = {|
  subjects: Subjects,
|}

export function SubjectCarousel({
  subjects: { heading, link, ageGroups, list, subjectLink },
}: Props) {
  const chunkedSubjectArray = chunkArray(list, Math.ceil(list.length / 2))

  return (
    <div className={styles.subjectCarousel}>
      <h2 className={styles.heading}>{heading}</h2>
      {chunkedSubjectArray.map((chunk, index) => (
        <Tags
          tags={chunk}
          ageGroups={ageGroups.list}
          animationDirection={index === 0 ? 'forwards' : 'reverse'}
          subjectLink={subjectLink}
          key={index}
        />
      ))}
      <SenecaLinkExternal link={link.to}>{link.text}</SenecaLinkExternal>
    </div>
  )
}

function Tags({
  tags,
  ageGroups,
  animationDirection = 'forwards',
  subjectLink,
}: {
  tags: {|
    name: string,
    category: string,
  |}[],
  ageGroups: {|
    name: string,
  |}[],
  animationDirection: 'forwards' | 'reverse',
  subjectLink: SubjectLink,
}) {
  const ageGroup = getAgeGroup(ageGroups)

  return (
    <motion.ul
      initial={{ x: animationDirection === 'forwards' ? '-50%' : '0' }}
      animate={{
        x: animationDirection === 'forwards' ? '0vw' : '-50%',
        transition: {
          repeat: Infinity,
          repeatType: 'loop',
          repeatDelay: 0,
          duration: isMobile ? 30 : 45,
          ease: 'linear',
        },
      }}
      className={styles.tagList}
    >
      {[...tags, ...tags].map((tag, index) => {
        return (
          <li className={styles.tag} key={index}>
            <a
              href={constructSubjectLink(subjectLink, tag.name, ageGroup)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon
                name={subjects[tag.category]?.icon ?? subjects.Other.icon}
                className={iconStyles.icon}
                data-subject={tag.category}
              />
              {tag.name} <Dot /> {ageGroup}
            </a>
          </li>
        )
      })}
    </motion.ul>
  )
}

function constructSubjectLink(
  { baseUrl, subject, ageGroup }: SubjectLink,
  subjectName: string,
  ageGroupName: string
) {
  const { url, query } = parseUrl(baseUrl)

  return stringifyUrl({
    url,
    query: {
      ...query,
      [subject]: subjectName,
      [ageGroup]: ageGroupName,
    },
  })
}

function getAgeGroup(
  ageGroups: {|
    name: string,
  |}[]
) {
  return ageGroups[Math.floor(Math.random() * ageGroups.length)].name
}

const subjects = {
  Biology: { icon: 'dna' },
  Business: { icon: 'money' },
  Chemistry: { icon: 'flask' },
  'Combined Science': { icon: 'atom' },
  'Computer Science': { icon: 'cpu' },
  'Design Technology': { icon: 'ruler' },
  Economics: { icon: 'money' },
  English: { icon: 'scroll' },
  'English Language': { icon: 'books' },
  'English Literature': { icon: 'chatCircleText' },
  'Food Preparation and Nutrition': { icon: 'forkKnife' },
  French: { icon: 'translate' },
  Geography: { icon: 'globeHemisphereWest' },
  German: { icon: 'translate' },
  History: { icon: 'scroll' },
  Maths: { icon: 'mathOperations' },
  'Media Studies': { icon: 'television' },
  Music: { icon: 'musicNotes' },
  'Non-Verbal Reasoning': { icon: 'strategy' },
  Other: { icon: 'books' },
  'Physical Education': { icon: 'personsArmsSpread' },
  Physics: { icon: 'magnet' },
  'Political Studies': { icon: 'gavel' },
  Psychology: { icon: 'brain' },
  'Religious Studies': { icon: 'church' },
  Science: { icon: 'atom' },
  Sociology: { icon: 'peoplePair' },
  Spanish: { icon: 'translate' },
  'Verbal Reasoning': { icon: 'chatsCircle' }
};
