import classnames from 'classnames'
import { Link } from 'gatsby'
import { GatsbyLinkProps } from 'gatsby-link'
import React from 'react'
import { useIntl } from 'react-intl'

import { intlLinkFromId } from '../../links/utils'

import styles from './SenecaLink.module.scss'

export function SenecaLink({
  openInNewTab,
  children,
  ...props
}: GatsbyLinkProps & { openInNewTab?: boolean }) {
  const { locale } = useIntl()
  return (
    <Link
      {...props}
      to={intlLinkFromId(props.to, locale)}
      target={openInNewTab ? '_blank' : null}
      className={styles.link}
    >
      <span>{children}</span>
    </Link>
  )
}

type Props = {|
  link: string,
  openInNewTab?: boolean,
  children: React.Node,
  className?: string,
|}

export function SenecaLinkExternal({
  link,
  openInNewTab,
  children,
  className,
}: Props) {
  return (
    <a
      href={link}
      target={openInNewTab ? '_blank' : null}
      rel="noopener noreferrer"
      className={classnames(styles.link, className)}
    >
      {children}
    </a>
  )
}
