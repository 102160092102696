import React from 'react'
import classnames from 'classnames'

import atom from 'src/common/images/icons/Atom.svg'
import books from 'src/common/images/icons/Books.svg'
import brain from 'src/common/images/icons/Brain.svg'
import chatCircleText from 'src/common/images/icons/ChatCircleText.svg'
import chatsCircle from 'src/common/images/icons/ChatsCircle.svg'
import church from 'src/common/images/icons/Church.svg'
import cpu from 'src/common/images/icons/Cpu.svg'
import dna from 'src/common/images/icons/Dna.svg'
import flask from 'src/common/images/icons/Flask.svg'
import forkKnife from 'src/common/images/icons/ForkKnife.svg'
import gavel from 'src/common/images/icons/Gavel.svg'
import globeHemisphereWest from 'src/common/images/icons/GlobeHemisphereWest.svg'
import magnet from 'src/common/images/icons/Magnet.svg'
import mathOperations from 'src/common/images/icons/MathOperations.svg'
import money from 'src/common/images/icons/Money.svg'
import musicNotes from 'src/common/images/icons/MusicNotes.svg'
import peoplePair from 'src/common/images/icons/PeoplePair.svg'
import personsArmsSpread from 'src/common/images/icons/PersonsArmsSpread.svg'
import ruler from 'src/common/images/icons/Ruler.svg'
import scroll from 'src/common/images/icons/Scroll.svg'
import strategy from 'src/common/images/icons/Strategy.svg'
import television from 'src/common/images/icons/Television.svg'
import translate from 'src/common/images/icons/Translate.svg'

import styles from './Icon.module.scss'

import { ReactSVG } from 'react-svg'

const icons = {
  atom,
  books,
  brain,
  chatCircleText,
  chatsCircle,
  church,
  cpu,
  dna,
  flask,
  forkKnife,
  gavel,
  globeHemisphereWest,
  magnet,
  mathOperations,
  money,
  musicNotes,
  peoplePair,
  personsArmsSpread,
  ruler,
  scroll,
  strategy,
  television,
  translate,
}

export function Icon({ name, className, ...props }) {
  return (
    <div className={classnames(styles.icon, className)} {...props}>
      <ReactSVG src={icons[name]} />
    </div>
  );
}
