// @flow
import React from 'react'
import { ReactSVG } from 'react-svg'
import styles from './Icon.module.scss'
import type { IconData } from '../types/IconData'
import useIconRotation from '../hooks/useIconRotation'

export function Icon({ iconData }: { iconData: IconData }) {
  const iconRef = useIconRotation()

  return (
    <div
      ref={iconRef}
      data-icon={iconData.name}
      className={styles.icon}
    >
      <ReactSVG src={iconData.src} />
    </div>
  )
}

export function IconBackground({ icons }: { icons: IconData[] }) {
  return (
    <div className={styles.iconBackground}>
      {icons.map(icon => (
        <Icon key={icon.name} iconData={icon} />
      ))}
    </div>
  )
}